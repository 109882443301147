// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.post-meta { font-size: 17.5px;}

/* NSM BEGIN Creation CSS tags */
/* Container for tags */
.post-tags {
    list-style: none;  /* Remove default list styling */
    padding: 0;        /* Remove padding */
}

/* Individual tags */
.post-tags li {
    display: inline-block;  /* Display tags in a line */
    margin: 5px 0px 5px 5px;  /* Space between tags */
}

/* Tag link styling */
.post-tags a {
    background-color: #444;  /* Dark gray background */
    color: #f2f2f2;          /* Light gray text for contrast */
    padding: 5px 5px;       /* Padding around text */
    border-radius: 5px;     /* Rounded corners */
    font-size: 0.9em;        /* Slightly smaller font size */
    text-decoration: none;   /* Remove underline */
    transition: background-color 0.3s;  /* Smooth transition for hover effect */
}

/* Hover effect for tags */
.post-tags a:hover {
    background-color: #555;  /* Slightly lighter gray on hover */
}
/* NSM END Creation CSS tags */

/* NSM BEGIN Creation CSS donation button */
/* Container for donation-button */
body .donation-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #a9a9a9; /* Neutral gray color */
    color: #000000; /* Black text color for contrast */
    border: 1px solid #ffffff; /* White border for better visibility on black background */
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    /* Optional: Add a hover effect */
    cursor: pointer;
}

body .donation-button:hover {
    background-color: #ffffff; /* White color on hover */
    color: #000000; /* Black text on hover */
    border: 1px solid #a9a9a9; /* Gray border on hover for a subtle effect */
}


/* NSM END Creation CSS donation button */

  
/* latin */
@font-face {
    font-family: 'OFL Sorts Mill Goudy TT';
    font-style: italic;
    font-weight: 400;
    src: url(/assets/fonts/-zk390Kk6OZq2W__Gh28iQi7zZ-9OZ4Alm9_BxobjL29Dg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
/* latin */
@font-face {
    font-family: 'OFL Sorts Mill Goudy TT';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fonts/-zk190Kk6OZq2W__Gh28iQi7zZ-9OZ4Alm9_BxoevL8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }



/* Base styling for the container */
ul.language-links {
    list-style-type: none;  /* Removes bullet points */
    padding: 0;            /* Removes default padding */
    margin: 0.5em 0;       /* Adds a bit of top and bottom spacing */
    font-family: Arial, sans-serif;  /* Setting a neutral font */
    display: inline-flex;  /* Makes the list horizontal */
    gap: 0.2em;            /* Smaller spacing between each language link */
    font-size: 12px;       /* Reduced font size */
}

/* Styling for individual language links */
ul.language-links li {
    display: inline-block;   /* Allows you to style each link individually */
    padding: 0.2em 0.5em;   /* Pads each link for better appearance */
    border: 1px solid #888; /* Provides a border to each link */
    border-radius: 5px;     /* Rounds the corners for aesthetics */
}

/* Styling for the actual anchor tags in the links */
ul.language-links li a {
    text-decoration: none;     /* Removes the underline */
    color: #aaa;               /* Light gray for visibility against dark background */
    transition: color 0.3s;    /* Smooth transition for hover effects */
}

/* Hover effect to make it clear a link is clickable */
ul.language-links li a:hover {
    color: #fff;  /* Brighter color on hover for emphasis */
}

/* Styling for the active language link */
.language-links li a.active {
    font-weight: bold;  /* Make the active language link bold */
    color: #fff; 
}


/* Base styling for the container */
ul.language-tags {
    list-style-type: none;     /* Removes bullet points */
    padding: 0;                /* Removes default padding */
    margin: 0.5em 0;           /* Adds a bit of top and bottom spacing */
    font-family: Arial, sans-serif;  /* Setting a neutral font */
    display: inline-flex;      /* Makes the list horizontal */
    gap: 0.2em;                /* Smaller spacing between each language link */
    font-size: 0.9em;          /* Slightly smaller font size */
}

/* Styling for individual language links */
ul.language-tags li {
    display: inline-block;     /* Allows you to style each link individually */
    padding: 0.2em 0.5em;     /* Pads each link for better appearance */
    border: 1px solid #333;   /* Provides a border to each link (dark gray) */
    border-radius: 5px;       /* Rounds the corners for aesthetics */
    background-color: #444;   /* Dark background color */
}

/* Styling for the actual anchor tags in the links */
ul.language-tags li a {
    text-decoration: none;     /* Removes the underline */
    color: #aaa;               /* Light gray for visibility against dark background */
    transition: color 0.3s;    /* Smooth transition for hover effects */
}

/* Hover effect to make it clear a link is clickable */
ul.language-tags li a:hover {
    color: #fff;               /* Brighter color on hover for emphasis */
}

/* Styling for the active language link */
.language-tags li a.active {
    font-weight: bold;         /* Make the active language link bold */
    color: #fff;               /* Brighter color for emphasis */
}
